import React, { useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
// import "./MessageOption.css";
import { addMessages } from "../../../store/conversation/actions";
import { apiHttp, apiIntegration } from "../../../services/api-http";
import {
  G4Cliente,
  G4Wait,
  G4AtendenteButton,
} from "../../../pages/conversation/components/styled";
import { WebchatContext } from "../../../context/WebchatContext"; 

import { ContentOption } from "./styled";

const MessageOption = (props) => {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);

  const { webChatId } = useContext(WebchatContext)
  const config = useSelector((state) => state.config);


  function sendMessageBot(message) {
    dispatch(
      addMessages([
        {
          cliente_ou_atendente: "cliente",
          texto: message,
          hora_da_mensagem: new Date().getTime(),
        },
      ])
    );

    apiIntegration
      .post(`/webchat/message/text/${webChatId}`, {
        chatId: user.idConversa,
        message: message,
      })
      .catch((err) => {
        console.log(err?.message || "Error!");
      });
  }

  const content = (
    <>
      <ContentOption>
        <u
          className="g4-message-option-link"
          style={{ cursor: "pointer" }}
          // onClick={() => sendMessageBot(props.options)}
        >
          {props.options}
        </u>

        {/* <a
          className="g4-message-option-link"
          href="#"
          onClick={() => sendMessageBot(props.options)}
        >
          {props.options}
        </a> */}
      </ContentOption>
    </>
  );

  return (
    <>
      {props.user === "cliente" && (
        <G4Cliente color={config.color}>{content}</G4Cliente>
      )}
      {props.user === "atendente" && (
        <G4AtendenteButton
          borderColor={config.color}
          onClick={() => {
            if(props.isLink)
            {
              window.open(props.link, "_blank");
              return
            }
            sendMessageBot(props.options)
          }}
          displayType="block"
        >
          {content}
        </G4AtendenteButton>
      )}
      {props.user === "wait" && <G4Wait color={config.color}>{content}</G4Wait>}
    </>
  );
};

export default MessageOption;
