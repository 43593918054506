import React, { createContext, useEffect, useState} from "react";
import { apiIntegration } from "../services/api-http";
// import { getConfigChat, getWebchatId } from "../components/logos";

export const WebchatContext = createContext();

const isFullScreen = process.env.REACT_APP_FULLSCREEN_MODE === 'true';

const getUrlWebChatName = () => {
  const url = window.location.href;
  const webChatId = url.split("?&")[1];
  return webChatId;
};

const getWebchatId = async (webchatName) => {
  const data = await apiIntegration.get(`open/webchat/findByName?nomeCanal=${webchatName}`);

  const webchatId = data?.data?.data?.id;

  return webchatId;
}

export function WebchatContextProvider({ children }) {
  const isMobile = process.env.REACT_APP_MOBILE_MODE === "true";

  const [modeWebchatFull, setModeWebchatFull] = useState(
    isMobile ? false : isFullScreen
  );
  const [inLoadingMessage, setInLoadingMessage] = useState(false);
  const [webChatId, setWebChatId] = useState(null);

  useEffect(() => {
    if (modeWebchatFull) {
      const webChatName = getUrlWebChatName();
      getWebchatId(webChatName).then((webChatId) => {
        setWebChatId(webChatId);
      });
      return;
    }
    const chatIdEnv = process.env.REACT_APP_WEBCHAT_ID;
    setWebChatId(chatIdEnv);
  }, [modeWebchatFull])

  const changeToggle = (newValue) => {
    setModeWebchatFull(!newValue);
  };

  return (
    <WebchatContext.Provider
      value={{
        modeWebchatFull,
        changeToggle,
        inLoadingMessage,
        setInLoadingMessage,
        isMobile,
        webChatId
      }}
    >
      {children}
    </WebchatContext.Provider>
  );
}
