import React from "react";
import { useSelector } from "react-redux";
import "./components/Header.css";
import { G4Header, G4Avatar, G4HeaderItens, SpanG4 } from "./components/styled";
import { FaMinus } from "react-icons/fa";
//import { IoIosArrowDown } from "react-icons/io";
import { useContext } from "react";
import { WebchatContext } from "../../context/WebchatContext";

const Header = ({ minimize, size, config }) => {
  const { modeWebchatFull, isMobile } = useContext(WebchatContext);

  const hours = useSelector((state) => state.hoursOfOut);
  function minus(event) {
    event.preventDefault();
    minimize(false);
  }

  return (
    <>
      <G4Header
        modeWebchatFull={modeWebchatFull}
        color={config.color}
        chatOpen={size}
      >
        <div>
          <div>
            <G4Avatar
              modeWebchatFull={modeWebchatFull}
              alt="avatar"
              chatOpen={size}
              src={config?.profilePic}
            />
          </div>
        </div>
        <G4HeaderItens
          textContrastColor={config.textContrastColor}
          modeWebchatFull={modeWebchatFull}
          onlineAtendent={hours}
        >
          <div modeWebchatFull={modeWebchatFull} className="circleOnline" />
          <div className="status">
            <SpanG4>{config.botName}</SpanG4>
          </div>
        </G4HeaderItens>
        {!isMobile && (
          <div className="minimize" title="Minimizar o webchat">
            <FaMinus
              onClick={(event) => minus(event)}
              size="23"
              color="#fff"
              type="button"
            />
          </div>
        )}
      </G4Header>
    </>
  );
};

export default Header;
