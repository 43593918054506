import React, { useContext } from "react";
import { Spinner } from "./style";
import { WebchatContext } from "../../context/WebchatContext";

const Loader = ({ color }) => {
  const { isMobile } = useContext(WebchatContext);

  return <Spinner isMobile={isMobile} picker={color} />;
};

export default Loader;
