import React, { useEffect, useState } from "react";
import { Rating } from "@material-ui/lab";
import {
  npsStyles,
  satisfactionStyles,
  getPropsHoverNPS,
  getPropsFilledNPS,
  getPropsHoverSatisfacao,
  getPropsFilledSatisfacao,
} from "./assets";
import { RatingBox } from "./assets/styled";

const FlexRating = ({ setRate, rate, type }) => {
  const [hover, setHover] = useState(-1);
  const [qtdStars, setQtdStars] = useState(0);

  const satisfacaoStyle = satisfactionStyles();
  const npsStyle = npsStyles();

  const [iconFilledVar, setIconFilled] = useState(null);
  const [iconHoverVar, setIconHover] = useState(null);

  useEffect(() => {
    switch (type) {
      case "satisfacao":
        setQtdStars(5);
        break;
      case "nps":
        setQtdStars(10);
        break;
      default:
        break;
    }
  }, [type, npsStyle, satisfacaoStyle]);

  return (
    <>
      <div>
        <RatingBox>
          <h3>
            {hover !== -1 ? hover : rate !== 0 && rate}
          </h3>
        </RatingBox>
      </div>
      <div>
        <Rating
          name="hover-feedback"
          max={qtdStars}
          value={rate}
          precision={1}
          onChange={(event, newValue) => {
            setRate(newValue);
            switch (type) {
              case "satisfacao":
                setIconFilled(
                  getPropsFilledSatisfacao(newValue, satisfacaoStyle)
                );
                break;
              case "nps":
                setIconFilled(getPropsFilledNPS(newValue, npsStyle));
                break;
              default:
                break;
            }
          }}
          onChangeActive={(event, newHover) => {
            setHover(newHover);
            switch (type) {
              case "satisfacao":
                setIconHover(
                  getPropsHoverSatisfacao(newHover, satisfacaoStyle)
                );
                break;
              case "nps":
                setIconHover(getPropsHoverNPS(newHover, npsStyle));
                break;
              default:
                break;
            }
          }}
          classes={{
            iconFilled: iconFilledVar,
            iconHover: iconHoverVar,
          }}
        />
      </div>
    </>
  );
};

export default FlexRating;
