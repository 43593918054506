import styled from "styled-components";

export const G4QueueImgDiv = styled.div`
  text-align: center;
  justify-content: center;
  display: flex;
  vertical-align: middle;
  width: 100%;
  height: 200px;
`;

export const G4QueueImg = styled.div`
  width: 200px;
  height: 170px;
  background: url(${(props) => props.src});
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
`;

export const G4ContainerSelect = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(465px - 42px);
  width: 100%;
  text-align: center;

  form {
    justify-content: center;
    align-items: center;
    height: 300px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  select {
    background: #f0f2f5;
    font-size: 0.9rem;
    border: none;
    transition: border-color 0.2s;
    transition: filter 0.2s;
    border: 1px solid transparent;
    outline: none;
    &:hover {
      border: 1px solid #d7d7d7;
      border-radius: 0.25rem;
      border-color: "#d7d7d7";
      cursor: pointer;
      filter: brightness(0.9);
    }
  }
`;

export const G4Erros = styled.p`
  text-align: left;
  margin-left: 11px;
  //font-family: Roboto, sans-serif;
  color: #f40f02;
`;

export const G4QueueButton = styled.button`
  padding: 10px 20px;
  cursor: pointer;
  margin-top: auto;
  height: 42px;
  font-size: 20px;
  //font-family: "Roboto", sans-serif;
  color: #fff;
  transition: 0.3s;
  bottom: 0px;
  width: 100%;
  border-radius: 0px 0px 10px 10px;

  ${(props) => props.color && `border: 1px solid ${props.color};`}
  ${(props) => props.color && `background: ${props.color};`}

  &:focus {
    outline: 0;
  }
`;

// &:hover {
//   background: #09793c;
//   border: 1px solid #09793c;
// }
