import { apiNps } from '../../../services/api-http';


class SurveyService {
  getTags = () => {
    return apiNps.get(`tags`);
  };

  getSurveyConfig = (surveyId) => {
    return apiNps.get(`configuration/survey/${surveyId}`);
  };

  sendInquiry = (conversationId, surveyId, data) => {
    return apiNps.post(
      `satisfaction/survey/answer/${conversationId}/${surveyId}`,
      data
    );
  };
}

export default new SurveyService();
