import produce from 'immer';

const INITIAL_STATE = {
  messages: [],
  id:'',
  sessionId:'',
  conversationAttendant:false
}

export default function conversation(state = INITIAL_STATE,action){ 
  switch(action.type){
    case '@conversation/ADD_MESSAGE':
      return produce(state, draft => {
        draft.messages = draft.messages.concat(action.payload.messages)
      });
    case '@conversation/ADD_SESSION_ID':
      return produce(state, draft => {
        draft.sessionId = action.payload.sessionId
      });
    case '@conversation/ADD_CONVERSATION_ATTENDANT':
      return produce(state, draft => {
        draft.conversationAttendant = action.payload.conversationAttendant
      });
    case '@conversation/CLEAR_CONVERSATION':
      return produce(state, draft => {
        draft.messages = [];
        draft.id = '';
      });
    default:
      return state;
  }
}