import React, { useContext } from "react";
import "./components/End.css";
import {
  G4ContainerEnd,
  G4EndImgDiv,
  G4EndImg,
  G4FooterEnd,
} from "./components/styled";
import { WebchatContext } from "../../context/WebchatContext";

const End = ({ config }) => {
  const { isMobile } = useContext(WebchatContext);

  return (
    <G4ContainerEnd isMobile={isMobile}>
      <G4EndImgDiv>
        <G4EndImg src={config.logoChat} alt="logo" />
      </G4EndImgDiv>

      <span role="img" aria-label="smile">
        Obrigado por entrar em contato conosco, estaremos sempre à disposição!
        😀
      </span>
      <G4FooterEnd color={config.color}></G4FooterEnd>
    </G4ContainerEnd>
  );
};

export default End;
