import ioClient from "socket.io-client";
import conversaAtendida from "./constraints/answeredConversation";
import encerrarCoversaCliente from "./constraints/endCoversationClient";
import clienteTranferido from "./constraints/tranferredClient";
import responderSurvey from "./constraints/answerSurvey";
import messageQueue from "./addMessageInQueue";
//import customParser from "socket.io-msgpack-parser";

const SOCKET_IO_URL = `${process.env.REACT_APP_PROXY_URL}public`;

class ApiSocket {
  _io;
  _idConnection;
  static getInstance() {
    if (!this._instance) {
      this._instance = new ApiSocket();
    }

    return this._instance;
  }

  get io() {
    if (!this._io) {
      this.connect();
    }
    return this._io;
  }

  idConnection() {
    if (this._io) return this._idConnection;
  }

  async connect(webChatId) {
    return new Promise((resolve, reject) => {
      if (this._io) resolve(this._io.id);
      this._io = ioClient.connect(SOCKET_IO_URL, {
        path: "/flex-chat-integration-socket",
        reconnectionAttempts: 3,
        transports: ["websocket"],
      });

      this._io.on("connect_error", (error) => {
        console.log(error?.message || "Socket Error!");
        reject(error);
      });

      this._io.on("connect", () => {
        this._idConnection = this._io.id;
        resolve(this._io.id);
      });

      this._io.on("cliente_transferido", (event) => {
        clienteTranferido(event);
      });

      this._io.on("conversa_transferida", (event) => {});

      this._io.on("conversa_atendida_cliente", (event) => {
        conversaAtendida(event, webChatId);
      });

      this._io.on("atendente_enviou_mensagem", async (event) => {
        messageQueue.addMessageInQueue(event);
      });

      this._io.on("encerrar_conversa_cliente", (event) => {
        localStorage.removeItem(`${process.env.KEY_ID_CONVERSATIO}-${webChatId}`);

        setTimeout(() => {
          encerrarCoversaCliente(event);
        }, 5000);
        localStorage.setItem(`conversationAttendant-${webChatId}`, "false");
      });

      this._io.on("send_survey", (event) => {
        responderSurvey(event.conversa_id, event.survey, true);
      });
    });
  }
}

export default ApiSocket.getInstance();
