import React from "react";
import "./Message.css";
import {
  G4Cliente,
  G4Wait,
  G4Atendente,
} from "../../../pages/conversation/components/styled";

const Message = ({ text, time, user, config }) => {
  const content = (
    <>
      <u
        style={{ cursor: "pointer" }}
        onClick={() => window.open(text, "_blank")}
      >
        {text}
      </u>
      <div id="date_G4_flex">
        {new Date(time).toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
          hour12: false,
        })}
      </div>
    </>
  );

  return (
    <>
      {user === "cliente" && (
        <G4Cliente color={config.color}>{content}</G4Cliente>
      )}
      {user === "atendente" && <G4Atendente>{content}</G4Atendente>}
      {user === "wait" && <G4Wait color={config.color}>{content}</G4Wait>}
    </>
  );
};

export default Message;
