import { produce } from "immer";

const INITIAL_STATE = {
  timeBegin: "",
  timeEnd: "",
  color: "lightgrey",
  useBot: false,
  botName: "",
};

export default function page(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "@config/ADD_CONFIG":
      return produce(state, (draft) => {
        draft.color = action.payload.color;
        draft.useBot = action.payload.useBot;
        draft.botName = action.payload.botName;
        draft.colorHyperlink = action.payload.colorHyperlink;
        draft.typeOfOpeningChat = action.payload.typeOfOpeningChat;
        draft.nameOfOpeningChat = action.payload.nameOfOpeningChat;
        draft.imageOfOpeningChat = action.payload.imageOfOpeningChat;
        draft.iconOfOpeningChat = action.payload.iconOfOpeningChat;
        draft.backgroundBallonAttendant =
          action.payload.backgroundBallonAttendant;
        draft.backgroundBallonClient = action.payload.backgroundBallonClient;
        draft.textColorBallonAttendant =
          action.payload.textColorBallonAttendant;
        draft.textColorBallonClient = action.payload.textColorBallonClient;
        draft.textContrastColor = action.payload.textContrastColor;
        draft.colorButtonLogin = action.payload.colorButtonLogin;
      });
    default:
      return state;
  }
}
