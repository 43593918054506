import atendenteEnviaMenssagem from "./constraints/attendantSendMessage";
class MessageQueue {
  constructor() {
    if (MessageQueue.instance) {
      return MessageQueue.instance;
    }

    this.delay = 1;
    this.queue = [];
    this.processing = false;
    this.processingMessage = false;
    this.observers = [];
    MessageQueue.instance = this;
  }

  async sleep(ms) {
    return new Promise((resolve) => {
      setTimeout(() => {
        this.processingMessage = false;
        this.notifyObservers();
        resolve();
      }, ms);
    });
  }

  async processQueue() {
    if (this.processing) return;
    this.processing = true;
    this.notifyObservers();
    this.idInterval = setInterval(async () => {
      if (this.queue.length > 0) {
        if (this.processingMessage) return;
        const message = this.queue.shift();
        if (
          ["text", "image", "video", "file"].indexOf(message.response_type) > -1
        ) {
          this.processingMessage = true;
          this.notifyObservers();
          this.addMessageInChat(message);
          await this.sleep(this.delay * 1000);
        } else {
          this.addMessageInChat(message);
        }
      } else {
        this.processing = false;
        this.notifyObservers();

        clearInterval(this.idInterval);
      }
    }, 300);
  }

  addMessageInQueue(message) {
    this.queue.push(message);
    this.processQueue();
  }

  async addMessageInChat(message) {
    let now = new Date();
    const newEvent = message?.message?.hora_da_mensagem
      ? [message.message]
      : [{ ...message, hora_da_mensagem: now }];
    atendenteEnviaMenssagem(newEvent);
  }

  addObserver(observer) {
    this.observers.push(observer);
  }

  notifyObservers() {
    this.observers.forEach((observer) => observer(this.processingMessage));
  }

  setDelay(delay) {
    this.delay = delay;
  }
}

const messageQueue = new MessageQueue();
export default messageQueue;
