import { combineReducers } from "redux";

import page from "./page/reducer";
import user from "./user/reducer";
import config from "./config/reducer";
import conversation from "./conversation/reducer";
import survey from "./survey/reducer";
import hoursOfOut from './hours/reducer';
import getNameFileReduce from "./reduce/getNameFileReduce";



const rootReducer = combineReducers({
  page,
  user,
  survey,
  config,
  conversation,
  hoursOfOut,
  getNameFileReduce
});

export default rootReducer;
