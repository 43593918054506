import styled from "styled-components";

export const G4ContainerCustomerExperience = styled.div`
  display: flex;
  flex-direction: column;
  // font-family: Roboto, sans-serif;
  font-size: 15px;
  overflow-y: scroll;
  height: 100%;
  align-items: center;
  box-sizing: border-box;
`;

export const G4CustomerExperienceImgDiv = styled.div`
  text-align: center;
  justify-content: center;
  display: flex;
  vertical-align: middle;
  width: 100%;
  height: 100px;
`;

export const G4CustomerExperienceImg = styled.img`
  max-width: 150px;
  max-height: 80px;
  margin: auto;
`;

export const G4FormCustomerExperience = styled.form`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

export const G4TextAreaCustomerExperience = styled.textarea`
  border: 1px solid #888;
  padding: 5px;
  margin-top: 2px;
  margin-bottom: 50px;
  border-radius: 6px;
  width: 90%;
  resize: none;

  &:focus {
    outline: 0;
  }
`;

export const G4TextAriaLabel = styled.label`
  width: 90%;
`;

export const G4ButtonCustomerExperience = styled.button`
  padding: 10px 20px;
  margin-left: 0px;
  font-size: 20px;
  //font-family: "Roboto", sans-serif;
  cursor: pointer;
  color: #fff;
  position: absolute;
  overflow: hidden;
  transition: 0.8s;
  bottom: 0px;
  right: 0;
  height: 42px;
  width: 100%;
  border-radius: 0px 0px 10px 10px;

  ${(props) => props.color && `background: ${props.color};`}
  ${(props) => props.color && `border: 1px solid ${props.color};`}

  &:focus {
    outline: 0;
  }
`;

// &:hover {
//   background: #09793c;
//   border: 1px solid #09793c;
// }

export const ForgotContainer = styled.div`
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;

  .img-logo-g4flex {
    text-align: center;
    margin-bottom: 25px;
  }

  .g4-button {
    background-color: #3f51b5;
    border-color: #3f51b5;
  }

  .g4-button:hover {
    background-color: #757de8;
    border-color: #757de8;
  }
`;

export const FormContainer = styled.div`
  flex-basis: 70%;
  display: flex;
  flex-direction: column;
  margin: 50px;
`;

export const G4CenteredDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  text-align: center;
`;

export const G4QuestionTag = styled.div`
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  text-align: center;
`;
