import styled from "styled-components";

export const MessageVideoStyle = styled.div`
  /* background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  background-image: url(${(props) => props.src}); */

  max-height: ${(props) => props.modeWebchatFull ? '400px' : '320px'};
  max-width: ${(props) => props.modeWebchatFull ? '400px' : '320px'};
  padding: 10px;
  border-radius: 5px;
  /* @media screen and (min-width: 1440px) {
    height: ${(props) => props.modeWebchatFull ? '400px' : 'none'};
    width: ${(props) => props.modeWebchatFull ? '400px' : 'none'};
  } */
  .date {
    font-size: 10px;
    margin-top: 26px;
    text-align: right;
    margin-left: 5px;
  }
`;
