const INITIAL_STATE = {
  name:'',
  email:'',
  queue:'',
  idConversa:'',
  socketId:'',
  logged: false,
  positionQueue: 0,
  telefone: ''
}

export default function user(state = INITIAL_STATE, action){
  switch (action.type) {
    case '@login/LOGIN':
      return {
        ...state,
        ...action.payload,
      }
    case '@login/ADD_QUEUE':
      return {
        ...state,
        ...action.payload
      }
    case '@login/ADD_ID_CONVERSATION':
      return {
        ...state,
        ...action.payload
      }
    case '@login/POSITION_QUEUE':
      return {
        ...state,
        ...action.payload
      }  
    case '@login/SOCKET_ID':
      return {
        ...state,
        ...action.payload
      }  
    default:
      return state;
  }
}
