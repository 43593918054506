import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { BsFillMicFill } from "react-icons/bs";
import { AiFillCheckCircle } from "react-icons/ai";
import { FaStopCircle } from "react-icons/fa";
import { AudioInput, AudioTime, ButtonAudio } from "./components/styled";
import { differenceInSeconds } from "date-fns";
import MicRecorder from "mic-recorder-to-mp3";
import { addMessages } from "../../../../store/conversation/actions";
import { apiIntegration } from "../../../../services/api-http";
import socket from "../../../../services/api-socket";
import { WebchatContext } from "../../../../context/WebchatContext";
let intervalId = null;
const Mp3Recorder = new MicRecorder({ bitRate: 128 });

const useRecorder = ({ id, config }) => {
  const [isRecording, setIsRecording] = useState(false);
  const [timeCount, setTimeCount] = useState("00:00");

  const { webChatId } = useContext(WebchatContext)
  const dispatch = useDispatch();
  const getTime = (startTime) => {
    let differenceFromNow = differenceInSeconds(
      new Date(),
      new Date(startTime)
    );

    const minutes = Math.floor(differenceFromNow / 60);
    differenceFromNow -= minutes * 60;
    const seconds = Math.floor(differenceFromNow);

    function getFomattedDigit(digit) {
      return `${String(digit).padStart(2, "0")}`;
    }

    return `${getFomattedDigit(minutes)}:${getFomattedDigit(seconds)}`;
  };

  useEffect(() => {
    if (isRecording) {
      Mp3Recorder.start()
        .then()
        .catch((e) => console.error(e));
      const nowTime = new Date();
      intervalId = setInterval(() => {
        setTimeCount(getTime(nowTime));
      }, 1000);
    } else {
      clearInterval(intervalId);
      intervalId = null;
      setTimeCount("00:00");
    }
  }, [isRecording]);

  const startRecording = () => {
    setIsRecording(true);
  };

  const stopRecording = () => {
    Mp3Recorder.stop();
    setIsRecording(false);
  };

  const sendRecording = () => {
    try {
      Mp3Recorder.stop()
        .getMp3()
        .then(([buffer, blob]) => {
          const dateNow = new Date();
          const formatedData = new FormData();

          formatedData.append("file", blob);
          formatedData.append("chatId", id); //body
          formatedData.append("cliente_ou_atendente", "cliente"); //
          formatedData.append("escrita_por", localStorage.getItem("name"));
          formatedData.append(
            "legenda",
            `audio_cliente_${dateNow.toLocaleDateString(
              "pt-br"
            )}-${dateNow.toLocaleTimeString("pt-br")}`
          );
          formatedData.append("response_type", "audio");
          formatedData.append("type", "audio");
          formatedData.append("idSocketCustomer", socket.idConnection());

          apiIntegration
            .post(`/webchat/message/midia/${webChatId}`, formatedData, {
              headers: {
                "content-type": "multipart/form-data",
              },
            })
            .then((response) => {
              const mensagem = {
                ...response.data.data,
                hora_da_mensagem: new Date().getTime(),
              };
              dispatch(addMessages(mensagem));

              //setValueInput("");
            })
            .catch((error) => {
              console.log(error?.message || "Error!");
            });
        })
        .catch((e) => console.log(e?.message || "Error!"));
    } catch (err) {
      console.error(err);
    }

    setIsRecording(false);
  };

  return (
    <>
      {!isRecording && (
        <ButtonAudio
          color="#fff"
          type="button"
          title="Enviar audio"
          onClick={startRecording}
          disabled={isRecording}
        >
          <BsFillMicFill size="80%" color={config.color} />
        </ButtonAudio>
      )}

      {isRecording && (
        <AudioInput>
          <ButtonAudio
            color="#fff"
            type="submit"
            title="Enviar"
            onClick={sendRecording}
            disabled={!isRecording}
          >
            <AiFillCheckCircle size="80%" color="#5cb85c" />
          </ButtonAudio>

          <div className="blinker" />

          <AudioTime>{timeCount}</AudioTime>

          <ButtonAudio
            color="#fff"
            type="button"
            title="Cancelar"
            onClick={stopRecording}
            disabled={!isRecording}
          >
            <FaStopCircle size="80%" color="#d9534f" />
          </ButtonAudio>
        </AudioInput>
      )}
    </>
  );
};

export default useRecorder;
