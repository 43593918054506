import styled, { css, keyframes } from "styled-components";

const loop = keyframes`
  to {
      transform: rotate(360deg);
    }
`;

export const Spinner = styled.div`
  margin-top: 20px;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  border: 5px solid rgba(0, 0, 0, 0.1);
  border-left-color: ${(props) => props.picker};
  animation: ${loop} 1s linear infinite;
  margin-bottom: 10px;

  ${(props) =>
    props.isMobile &&
    css`
      position: absolute;
      top: 50%;
      left: 45%;
    `}
`;
