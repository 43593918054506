export function addMessages(messages){
  return {
    type:'@conversation/ADD_MESSAGE',
    payload: {
      messages
    }
  }
}

export function addId(id){
  return {
    type:'@conversation/ADD_ID',
    payload: {
      id
    }
  }
}

export function addSessionId(sessionId){
  return {
    type:'@conversation/ADD_SESSION_ID',
    payload: {
      sessionId
    }
  }
}

export function addConversationAttendant(){
  return {
    type: '@conversation/ADD_CONVERSATION_ATTENDANT',
    payload: {
      conversationAttendant: true
    }
  }
}

export function clearConversation(){
  return {
    type: '@conversation/CLEAR_CONVERSATION',
  }
}