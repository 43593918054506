import React, { useEffect, useRef, useState } from "react";
import { MessageAudio, Container } from "./style";
import {
  G4Cliente,
  G4Wait,
  G4Atendente,
} from "../../../pages/conversation/components/styled";

import { MdPause, MdPlayArrow } from "react-icons/md";

const Audio = ({ user, source, config }) => {
  const [player, setPlayer] = useState(false);
  const [duration, setDuration] = useState(0);
  const [time, setTime] = useState(0);

  const audio = useRef();

  useEffect(() => {
    if (time === duration) {
      setPlayer(false);
    }
  }, [duration, time]);

  function play() {
    if (audio.current.paused) {
      setPlayer(!player);
      audio.current.play();
    } else {
      audio.current.pause();
      setPlayer(!player);
    }
  }

  function getDuration(e) {
    // const percent = ((e.currentTarget.currentTime / e.currentTarget));
    const time = e.currentTarget.currentTime; /// 60

    setTime((time / 60).toFixed(2));
    if (time === duration) {
      alert("terminou");
    }
  }

  const formatedTime = time.toString().replace(".", ":");
  const formatedDuration = duration.toString().replace(".", ":");

  const content = (
    <Container>
      <MessageAudio color={config.color}>
        {player === false && (
          <MdPlayArrow size="25" color="gray" type="button" onClick={play} />
        )}
        {player !== false && time !== duration && (
          <MdPause size="25" color="gray" type="button" onClick={play} />
        )}
        <input
          onChange={() => {}}
          className="seekbar"
          min={0}
          value={time}
          max={duration}
          type="range"
          step="0.01"
        />
        <audio
          ref={audio}
          onLoadedData={(event) => {
            setDuration((event.currentTarget.duration / 60).toFixed(2));
          }}
          onTimeUpdate={getDuration}
          src={source}
        ></audio>
      </MessageAudio>
      {player ? (
        <div className="times">{formatedTime}</div>
      ) : (
        <div className="times">{formatedDuration}</div>
      )}
    </Container>
  );

  return (
    <>
      {user === "cliente" && (
        <G4Cliente color={config.color}>{content}</G4Cliente>
      )}
      {user === "atendente" && <G4Atendente>{content}</G4Atendente>}
      {user === "wait" && <G4Wait color={config.color}>{content}</G4Wait>}
    </>
  );
};

export default Audio;
