import axios from "axios";

export const apiHttp = axios.create({
  baseURL: `${process.env.REACT_APP_CHANNEL_URL}`,
});

export const apiHttpNotOpen = axios.create({
  baseURL: `${process.env.REACT_APP_PROXY_URL}`,
});

export const apiIntegration = axios.create({
  baseURL: `${process.env.REACT_APP_CHAT_INTEGRATION}`,
});

export const apiNps = axios.create({
  baseURL: `${process.env.REACT_APP_SURVEY_URL}`,
});
