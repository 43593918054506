import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: ${(props) => (props.isMobile ? "90vh" : "calc(465px - 42px)")};

  width: 100%;
`;
