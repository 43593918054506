
import { addConversationAttendant } from '../../store/conversation/actions';
import { nextPage } from '../../store/page/actions';
import store from '../../store';

const WAIT = 'Wait';

export default function clienteTranferido(event){
    store.dispatch(addConversationAttendant());
    store.dispatch(nextPage(WAIT));
}