import React, { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { isMobile, browserName } from "react-device-detect";
import { apiHttp } from "../../services/api-http";
import "./components/Queue.css";
import {
  G4QueueImgDiv,
  G4QueueImg,
  G4ContainerSelect,
  G4Erros,
  G4QueueButton,
} from "./components/styled";
import socket from "../../services/api-socket";
import { nextPage } from "../../store/page/actions";
import { WebchatContext } from "../../context/WebchatContext"; 

import Loader from "../../components/loader";

const WAIT = "Wait";

const Queue = ({ config }) => {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const [queues, setQueues] = useState([]);
  const { register, handleSubmit, errors } = useForm();

  function logInQueue({ queue }) {
    let mobile;
    let origem;
    let platform = navigator.platform;

    if (isMobile) {
      origem = browserName;
      mobile = true;
    } else {
      origem = browserName;
      mobile = false;
    }

    socket.io.emit("entrar_na_fila", {
      nome: user.name,
      email: user.email,
      fila: queue,
      telefone: user.telefone,
      navegador: origem,
      plataforma: platform,
      isMobile: mobile,
    });

    dispatch(nextPage(WAIT));
  }

  useEffect(() => {
    apiHttp
      .get("/filas")
      .then((response) => {
        setQueues(response.data);
      })
      .catch((err) => {
        console.log(err?.message || "Error!");
      });
  }, []);

  return (
    <G4ContainerSelect>
      <G4QueueImgDiv>
        <G4QueueImg src={config.logoChat} />
      </G4QueueImgDiv>

      <form onSubmit={handleSubmit(logInQueue)}>
        {queues.length === 0 ? (
          <Loader color={config.color} />
        ) : (
          <select
            name="queue"
            className="g4-queue-select"
            ref={register({
              required: "* Campo obrigatório",
            })}
          >
            <option value="">Escolha sua fila</option>
            {queues.map((queue) => (
              <option key={queue.nome} value={queue.nome}>
                {queue.nome}
              </option>
            ))}
          </select>
        )}
        {errors.queue && <G4Erros>{errors.queue.message}</G4Erros>}
        {queues.length === 0 ? (
          <G4QueueButton disabled type="submit" color={config.color}>
            Entrar na fila
          </G4QueueButton>
        ) : (
          <G4QueueButton type="submit" color={config.color}>
            Entrar na fila
          </G4QueueButton>
        )}
      </form>
    </G4ContainerSelect>
  );
};

export default Queue;
